import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { db } from '../firebase';
import { hideLoader, showLoader } from '../Store/Sclies/loaderSlice';
import moment from 'moment';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

const Users = () => {
  const dispatch = useDispatch();
  const [row, setRow] = useState([]);
  const [pageSize, setPageSize] = useState(7);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const handleClose = () => setOpen(false);
  const [loader, setLoader] = useState(false);
  const isLoading = useSelector((state) => state.loader);
  useEffect(() => {
    dispatch(showLoader());

    const ref = collection(db, 'Users');

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const documents = snapshot.docs.map((doc) => ({
        ...doc.data()
      }));
      documents.sort((a, b) => {
        const createdAtA = parseInt(a.createdAt);
        const createdAtB = parseInt(b.createdAt);
        if (createdAtA > createdAtB) {
          return -1;
        }
        if (createdAtA < createdAtB) {
          return 1;
        }
        return 0;
      });

      let i = documents.length;
      const reversedDocuments = documents.map((doc) => ({
        ...doc,
        id: i--
      }));

      setRow(reversedDocuments);
      dispatch(hideLoader());
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleDelete = async (id) => {
    setOpen(true);
    setId(id);
  };

  const handleDeleteUser = async () => {
    try {
      setLoader(true);
      await deleteDoc(doc(db, 'Users', id));
      axios.post(
        'https://europe-west3-gogaudi-1c29d.cloudfunctions.net/deleteUser',
        { userId: id }
      );
      setLoader(false);
      setOpen(false);
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const columns = [
    {
      flex: 0.05,
      field: 'id',
      headerName: 'id',
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600 }}
              >
                {id}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.2,
      minWidth: 120,
      field: 'email',
      headerName: 'Email',
      renderCell: (params) => {
        const { row } = params;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {row?.email}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.2,
      minWidth: 120,
      field: 'name',
      headerName: 'First Name',
      renderCell: (params) => {
        const { row } = params;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {row?.name}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.2,
      minWidth: 120,
      field: 'LastName',
      headerName: 'Last Name',
      renderCell: (params) => {
        const { row } = params;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {row?.LastName}
              </Typography>
            </Box>
          </Box>
        );
      }
    },

    {
      flex: 0.1,
      minWidth: 80,
      field: 'year',
      headerName: 'Birthday',
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {row?.birthdate}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.1,
      minWidth: 20,
      field: 'Age',
      headerName: 'Age',
      renderCell: (params) => {
        const { row } = params;
        const currentYear = new Date().getFullYear();
        const year = parseInt(row.birthdate.split('/')[2], 10);
        const age = currentYear - year;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {' '}
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {age}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.1,
      minWidth: 50,
      field: 'gender',
      headerName: 'Gender',
      renderCell: (params) => {
        const { row } = params;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {row.gender}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    // Age Verification
    // {
    //   flex: 0.1,
    //   minWidth: 50,
    //   field: 'Status',
    //   headerName: 'Age Verification Status',
    //   renderCell: (params) => {
    //     const { row } = params;
    //     return (
    //       <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //         <Box
    //           sx={{
    //             display: 'flex',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //             ml: 1
    //           }}
    //         >
    //           <Chip
    //             label={row.Document_varification}
    //             color={
    //               row.Document_varification === 'Approved'
    //                 ? 'success'
    //                 : row.Document_varification === 'Rejected'
    //                 ? 'error'
    //                 : row.Document_varification === 'Applied'
    //                 ? 'secondary'
    //                 : 'warning'
    //             }
    //           />
    //         </Box>
    //       </Box>
    //     );
    //   }
    // },
    {
      flex: 0.1,
      minWidth: 140,
      field: 'time',
      headerName: 'Sign-up Date',
      renderCell: (params) => {
        const { row } = params;
        const dateChange = row?.createdAt;
        const readableDate = moment(+dateChange).format('DD-MM-YYYY HH:mm');
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ml: 1
              }}
            >
              {readableDate}
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.1,
      minWidth: 50,
      field: 'delete',
      headerName: 'Delete User',
      renderCell: (params) => {
        const { row } = params;
        const uid = row?.uidlist;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ml: 1
              }}
            >
              <Tooltip title="User Delete">
                <IconButton
                  onClick={() => {
                    handleDelete(uid);
                  }}
                  type="button"
                  sx={{
                    color: 'red',
                    ':hover': {
                      bgcolor: '#6366F1',
                      color: 'white',
                      marginRight: '5px'
                    }
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      }
    }
  ];

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 4
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">Users</Typography>
            </Stack>
          </Stack>

          <Card>
            <DataGrid
              loading={isLoading}
              rows={row}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[7, 10, 25, 50]}
              disableRowSelectionOnClick
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              sx={{
                height: '700px',
                borderRadius: 2,
                borderTop: 1,
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main'
                },
                '& .MuiDataGrid-cell': {},
                '& .MuiDataGrid-row': {
                  borderBottom: '1px solid Lightgray'
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '1px solid Lightgray',
                  bgcolor: 'lightgray',
                  paddingLeft: 2
                }
              }}
            />
            {/* </CardContent> */}
          </Card>
        </Stack>
      </Container>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ margin: '10px', marginBottom: '0' }}>
          Delete user
        </DialogTitle>
        <Box position="absolute" top={10} right={10}>
          <Tooltip title="close">
            <IconButton
              onClick={handleClose}
              sx={{
                ':hover': {
                  bgcolor: '#6366F1',
                  color: 'white',
                  marginLeft: 'auto'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <DialogContent>
          <Typography>Do you really want to delete the user?</Typography>
        </DialogContent>
        <DialogActions sx={{ marginBottom: '10px' }}>
          <Button
            color="primary"
            onClick={() => setOpen(false)}
            variant="contained"
          >
            No
          </Button>
          <Button color="error" onClick={handleDeleteUser} variant="contained">
            {loader ? (
              <CircularProgress size={30} style={{ color: '#fff' }} />
            ) : (
              'Yes'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Users;
